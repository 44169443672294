/* eslint-disable */
import {
  Typography,
  Grid,
  Card,
  RACTable,
  RACTableRow,
  RACTableCell,
  List,
  ListItem,
  CardContent,
} from "@rentacenter/racstrap";
import { ReactComponent as SortdownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortupIcon } from "../../../assets/images/sort-icon-up.svg";
import { ReactComponent as Clearance } from "../../../assets/images/Clearance.svg";
import { globalStyles } from "../Styles/globalstyles";
import { PricingQueueContext } from "./PricingQueueContext";
import { useContext, useEffect } from "react";
import moment from "moment";
import { REMOVE_PRICING } from "../../../constants/constants";
import PricingContext, { PricingDetailsProvider } from "../Pricing/PricingContext";
export default function RemovePricingApproval() {
  const {
    indexValue,
    setIndexValue,
    setHideMainPage,
    comments,
    sortDirection,
    setComments,
  } = useContext(PricingQueueContext);
  const {
    pricingHeader, setPricingHeader,integrateWithToolTip
  } = useContext(PricingDetailsProvider);
  const Classes = globalStyles();

  const sortArray = (
    columnId: string, // columns name with is key to identify the column
    index: number, // the index if the column clicked.
    datacolumn: any, // which is the table header,
    dataValues: any,// which is the table body.
    isDate: boolean = false
  ) => {
    let sortedData;

    if (isDate) {
      sortedData =
        datacolumn[index].sortDirection == "DESC"
          ? dataValues.sort((a: any, b: any) => {
            let date1 = moment(b[columnId]);
            let date2 = moment(a[columnId]);
            return date2.isBefore(date1) ? -1 : 1;
          }
          )
          : dataValues.sort((a: any, b: any) => {
            let date1 = moment(b[columnId]);
            let date2 = moment(a[columnId]);
            return date1.isBefore(date2) ? -1 : 1;
          });
    } else {
      sortedData =
        datacolumn[index].sortDirection == "DESC"
          ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
          : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
    }
    const reorderColumns: any = datacolumn;
    reorderColumns[index].sortDirection =
      reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";

    setIndexValue({...indexValue, ['removePricing']:[...sortedData]});
    setPricingHeader([...reorderColumns]);
  };
  const removePricingTableHeader = () => (
    <>
      {
        pricingHeader.filter((item: any) => item.removePricing).map((item: any, index: number) =>  (
          <>
            <RACTableCell key={index} >
              {
                item.isSortable ? (
                  <a
                    style={{
                      color: "black",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    id={item.key}
                    onClick={() =>
                      sortArray(
                        item.key,
                        index,
                        pricingHeader,
                        indexValue.removePricing,
                        item.key == "startDate" || item.key == 'endDate' ? true : false
                      )
                    }
                  >
                    {item.heading + "  "}
                    {item.isSorted || item.isSorted === true ? (
                      (item.sortDirection.toLowerCase() == sortDirection.DESC.toLowerCase() )? (
                        <SortupIcon className={Classes.ml0} />
                      ) : (
                        <SortdownIcon className={Classes.ml0} />
                      )
                    ) : null}
                  </a>
                )
                  :
                  (item.heading)
              }

            </RACTableCell>
          </>
)
)

      }
    </>
  )
  const removePricingTableBody = () => (
    <>
      {
        indexValue.removePricing ? (
          indexValue.removePricing.map((item: any, index : any) => (
            <RACTableRow
              key={index}
              className={`${Classes.bgTransparent} `}
            >
              <RACTableCell>
                <Clearance/>
              </RACTableCell>
            {
              pricingHeader.filter((item : any)=>(item.removePricing && item.heading !='')).map((pricingItem : any)=>(
                <RACTableCell title={
                  (pricingItem.key == 'rmsItemDescription' || pricingItem.key == 'bracketName')  && (item[pricingItem.key]?.length > 25) ? (item[pricingItem.key]) : ''
                }>
                  {(pricingItem.key =='zoneNumber' || pricingItem.key =='departmentName' || pricingItem.key =='subdepartmentName' || pricingItem.key =='rmsItemNumber' || pricingItem.key == 'pricingType') ?
                   item[pricingItem.key] : 
                   (pricingItem.key == 'rmsItemDescription' || pricingItem.key == 'bracketName')
                  ? 
                  (item[pricingItem.key]?.length > 25 ? item[pricingItem.key]?.slice(0, 25) + "..."  : item[pricingItem.key]) : 
                  integrateWithToolTip(item, pricingItem.key,(pricingItem.key =='startDate' || pricingItem.key == 'endDate') ? true : false) }
                </RACTableCell>
                )
              )
            }

            </RACTableRow>
          ))
        )
          :
          null
      }
    </>
  )
  return (
    <>
      <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
        {/* braedcrumbs starts  */}
        <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
          <ListItem
            style={{ paddingLeft: "0" }}
            className={`${Classes.breadcrumbItemActive} `}
          >
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndexValue("");
                setHideMainPage(false);
              }}
            >
              Pricing Queue
            </a>
          </ListItem>
          <ListItem
            className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
          >
            <a>{REMOVE_PRICING}</a>
          </ListItem>
        </List>
        {/* braedcrumbs ends  */}

        <Grid container spacing={2}>
          {/* Update remove pricing starts  */}
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h4">{REMOVE_PRICING}</Typography>
            <Card /* className={`${Classes.p3} `} */ style={{borderRadius:'12px', position:"relative"}}>
              <CardContent>
                <Grid item xs={12} md={12} lg={12}
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    maxHeight: "60vh",
                    minHeight: '40vh'
                  }}>
                  <RACTable
                    renderTableHead={removePricingTableHeader}
                    renderTableContent={removePricingTableBody}
                    style={{
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                    className={Classes.PricingGrid}
                  >
                  </RACTable>

                </Grid>
                <Grid md={12}>
                  <Typography variant="h4" style={{ marginTop: "2%" }}>
                    Comments
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <textarea
                      rows={6}
                      style={{
                        resize: "none",
                        width: "100%",
                        border: "1px solid lightgray !important",
                      }}
                      disabled={
                        indexValue?.status?.toUpperCase() == "QUEUED" ||
                        indexValue?.status?.toUpperCase() == "WAITING"
                          ? false
                          : true
                      }
                      value={comments}
                      onChange={(e: any) => {
                        setComments(e.target.value);
                      }}
                    ></textarea>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>         
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}