/* eslint-disable */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
//import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { globalStyles } from '../Pricing/Styles/globalstyles';
import { PricingReportStyle } from '../Pricing/Styles/PricingReport';
import { ReactComponent as RacFilter } from '../../assets/images/RacSort.svg';
import { ReactComponent as FilterClose } from '../../assets/images/filter-close-icon.svg';
// import { ReactComponent as Info } from '../../assets/images/info-button.svg';
import { ReactComponent as SortIcon } from '../../assets/images/sort-icon.svg';
import { ReactComponent as SortIconUp } from '../../assets/images/sort-icon-up.svg';
import { ReactComponent as NorecordsIcon } from '../../assets/images/No-records.svg';
import { ReactComponent as SuccesIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as SomethingWrong } from '../../assets/images/no-records-found.svg';
// import { ContainerContext } from '../../app/App';
// import { CustomPropInterface } from '../../index';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';
import ReactPaginate from 'react-paginate';
import {
  getZoneDD,
  getReportGridData,
  generateReport,
  getXlFile,
} from '../../api/user';
//import { getUserID,getZoneDD, getReportGridData, generateReport, getXlFile, getReportGrid } from '../../api/user';

//import Tooltip from "@material-ui/core/Tooltip"; // used in Pricing upload
//import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router';

import {
  RACTableCell,
  RACTable,
  RACTableRow,
  Grid,
  Card,
  CardContent,
  RACButton,
  Typography,
  RACSelect,
  RACDatePicker,
  RACInfoIcon,
  RACModalCard,
  CircularProgress,
} from '@rentacenter/racstrap';
import MissingPrice from '../shared/MissingPriceHazard/MissingPrice';

export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  elipse?: boolean;
  handleColumnClick1?: () => void;
  handleColumnClick2?: () => void;
  subColumns?: columns[];
  index?: string;
  isAction?: boolean;
}

export default function PricingReport() {
  //styles
  const classes = globalStyles();
  const pricing = PricingReportStyle();
  //state variables
  const [maindata, setmaindata] = useState([]); // eslint-disable-line
  const [tabledata, settabledata] = useState<any>([]);
  //const [companyCode, setcompanyCode] = useState('');
  //const [empid, setempid] = useState('');

  const [loader, setloader] = useState('');
  const [gridbind, setgridbind] = useState(false);
  const [popupop, setpopupop] = useState('');
  const [errormsg, seterrormsg] = useState('');
  const [errormsgTo, seterrormsgTo] = useState('');

  const filterobjectmodel = { Status: '', To: '' };
  const [filterobject, setfilterobject] = useState<any>(filterobjectmodel); //filter object
  const [fromdt, setfromdt] = useState('');

  const [genZone, setgenZone] = useState([]);
  const [genType, setgenType] = useState('');
  const [zonevalue, setzonevalue] = useState([]);

  const history = useHistory();
  //pagination state variable
  //to store the data items of sliced in pagination
  const [currentItems, setCurrentItems] = useState<any>([]);
  //to set the total pages
  const [pageCount, setpageCount] = useState(0);
  //dividing the data
  const [itemOffset, setItemOffset] = useState(0);
  //Page OutOff
  const [outoffrec, setoutoffrec] = useState(0);
  const [totrecord, settotrecord] = useState(0);

  const [nocontent, setnocontent] = useState(false);
  //Download Button logic
  const [Downloadloader, setDownloadloader] = useState('-1');
  const [pginitial, setpginitial] = useState(-1);
  const [selectAll, setSelectAll] = useState<boolean>(true);

  //Use Context
  //const containerData = useContext(ContainerContext) as CustomPropInterface;

  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  };

  const headcolumns = [
    {
      columnId: 'zone',
      sortOrder: sortDirection.ASC,
      columnTitle: 'Zone',
      IsSorted: false,
      IsSortable: false,
    },
    {
      columnId: 'type',
      sortOrder: sortDirection.ASC,
      columnTitle: 'Type',
      IsSorted: false,
      IsSortable: true,
    },
    {
      columnId: 'date',
      sortOrder: sortDirection.ASC,
      columnTitle: 'Date & Time',
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnId: 'status',
      sortOrder: sortDirection.ASC,
      columnTitle: 'Status',
      IsSorted: false,
      IsSortable: true,
    },
    {
      columnId: 'action',
      sortOrder: sortDirection.ASC,
      columnTitle: 'Action',
      IsSorted: false,
      IsSortable: false,
    },
  ];
  const [headerdata, setheaderdata] = useState(headcolumns);
  const monthDateFormat = 'MM/DD/YYYY';
  const yearDateFormat = 'YYYY-MM-DD';
  const timeZoneChicago = 'America/Chicago';
  const fontOpens = 'OpenSans-regular';
  const borRadius = '25px !important';

  //debugger
  useEffect(() => {
    const Griddata = async () => {
      setloader('masterLoader');
      const responses = await Promise.all([getReportGridData(), getZoneDD()]);
      if (
        responses[0].status == 200 &&
        responses[1].status == 200 &&
        responses[0].status != undefined &&
        responses[1].status != undefined &&
        responses[0].status != null &&
        responses[0].status != '' &&
        responses[1].status != null &&
        responses[1].status != ''
      ) {
        if (
          Array.isArray(responses[0].data) == false &&
          Array.isArray(responses[0].data.result) == false
        ) {
          // use seperate state variable for "Emptyres"
          setloader('');
          optionsfill(responses[1].data.result);
          setnocontent(true);
        } else if (
          responses[0].data === undefined &&
          responses[0].data === null &&
          responses[1].data === undefined &&
          responses[1].data === null
        ) {
          setloader('');
          setpopupop('GridError');
        } else {
          setloader('');
          setmaindata(responses[0].data.result);
          settotrecord(responses[0].data.result.length);
          setpageCount(Math.ceil(responses[0].data.result.length / 25));
          optionsfill(responses[1].data.result);
          const dateArry = responses[0].data.result.filter((el: any) => {
            return (
              new Date(el.date).getMonth() +
              '/' +
              new Date(el.date).getDate() +
              '/' +
              new Date(el.date).getFullYear()
            ); //return new Date(el.date).toLocaleString('en-US', {timeZone: 'America/New_York',});
          });
          const sortedArry = dateArry.sort((a: any, b: any) =>
            b.date < a.date ? -1 : 1
          );
          settabledata(sortedArry);
          const itemsPerPage = 25;
          const endOffset = itemOffset + itemsPerPage;
          setCurrentItems(sortedArry.slice(itemOffset, endOffset));
          setpginitial(0);
        }
      } else {
        setloader('');
        setpopupop('GridError');
      }
    };
    Griddata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //first useEffect with company code

  // useEffect(() => {
  //   const Customerdet = async () => {
  //     setloader('masterLoader');
  //     let Gridreq: any;
  //     if(containerData.GetEmployeeId() == undefined && containerData.GetEmailId() == undefined){
  //       const currentuserresponse = await getUserID();
  //       console.log('GetCurrent User resposne',currentuserresponse);
  //       console.log('GetCurrent User resposne employeeID',currentuserresponse.data.employeeId);
  //       console.log('GetCurrent User resposne emailid',currentuserresponse.data.email);
  //       if(currentuserresponse.data.employeeId !='' && currentuserresponse.data.employeeId !=null && currentuserresponse.data.employeeId !=undefined){
  //         Gridreq = currentuserresponse.data.employeeId;
  //         setempid(Gridreq);
  //       }
  //       else if(currentuserresponse.data.email != '' && currentuserresponse.data.email !=null && currentuserresponse.data.email !=undefined){
  //         Gridreq = currentuserresponse.data.email;
  //         setempid(Gridreq);
  //       }
  //       else{
  //       setloader('');
  //       setpopupop('GridError');
  //       }
  //     }
  //     else if (containerData.GetEmployeeId() !== '' && containerData.GetEmployeeId() !== null && containerData.GetEmployeeId() !== undefined) {
  //       Gridreq = containerData.GetEmployeeId();
  //       setempid(Gridreq);
  //     }
  //     else if (containerData.GetEmailId() !== '' && containerData.GetEmailId() !== null && containerData.GetEmailId() !== undefined) {
  //       Gridreq = containerData.GetEmailId();
  //       setempid(Gridreq);
  //     }
  //     else {
  //       setloader('');
  //       setpopupop('GridError');
  //     }
  //     if (Gridreq !== undefined) {
  //       const responses = await Promise.all([getReportGridData(Gridreq), getZoneDD()]);
  //       console.log("Resposne of getReportGridData ", responses[0]);
  //       console.log("Resposne of getZoneDD", responses[1]);
  //       if (responses[0].status == 200 && responses[1].status == 200
  //         && responses[0].status != undefined && responses[1].status != undefined
  //         && responses[0].status != null && responses[0].status != ''
  //         && responses[1].status != null && responses[1].status != '') {
  //         setloader('');
  //         setmaindata(responses[0].data.result);
  //         settotrecord(responses[0].data.result.length);
  //         setpageCount(Math.ceil(responses[0].data.result.length / 25));
  //         setcompanyCode(responses[0].data.companyCode);
  //         optionsfill(responses[0].data.companyCode, responses[1].data.result);
  //         const dateArry = responses[0].data.result.filter((el: any) => {
  //           return (new Date(el.date).getMonth() + '/' + new Date(el.date).getDate() + '/' + new Date(el.date).getFullYear());   //return new Date(el.date).toLocaleString('en-US', {timeZone: 'America/New_York',});
  //         });
  //         const sortedArry = dateArry.sort((a: any, b: any) =>
  //           b.date < a.date ? -1 : 1
  //         );
  //         settabledata(sortedArry);
  //         const itemsPerPage = 25;
  //         const endOffset = itemOffset + itemsPerPage;
  //         console.log(`Loading items from ${itemOffset} to ${endOffset} in useEffect1`);
  //         console.log("table dataaaaaaa", tabledata)
  //         setCurrentItems(sortedArry.slice(itemOffset, endOffset));
  //         console.log("currentItems first UseEffect", currentItems);
  //       }
  //       else {
  //         setloader('');
  //         setpopupop('GridError');
  //       }
  //     }
  //     else {
  //       setloader('');
  //       setpopupop('GridError');
  //     }

  //   }
  //   Customerdet();
  //   console.log('containerData', containerData);
  //   console.log('Get Mail Id', containerData.GetEmailId());
  //   console.log('Get Employee Id', containerData.GetEmployeeId());
  // }, []);

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    const genRepLoad = async () => {
      setloader('masterLoader');
      const gridresponse = await getReportGridData();
      if (
        gridresponse.status === 200 &&
        gridresponse.status != '' &&
        gridresponse.status != null &&
        gridresponse.status != undefined
      ) {
        setloader('');
        setmaindata(gridresponse.data.result);
        //Sorting the table data
        // eslint-disable-next-line sonarjs/no-identical-functions
        const dateArry = gridresponse.data.result.filter((el: any) => {
          return (
            new Date(el.date).getMonth() +
            '/' +
            new Date(el.date).getDate() +
            '/' +
            new Date(el.date).getFullYear()
          );
        });
        const sortedArry = dateArry.sort((a: any, b: any) =>
          b.date < a.date ? -1 : 1
        );
        settabledata(sortedArry);
      } else {
        setpopupop('GridError');
      }
      setgridbind(false);
    };
    gridbind ? genRepLoad() : '';
  }, [gridbind]);

  //second useEffect with company code logic
  // useEffect(() => {
  //   // eslint-disable-next-line sonarjs/no-redundant-boolean
  //   const genRepLoad = async () => {
  //     setloader('masterLoader');
  //     const gridresponse = await getReportGrid(empid, companyCode);
  //     console.log("GRID RESPOSNE", gridresponse.data.result);
  //     if (gridresponse.status === 200 && gridresponse.status != '' && gridresponse.status != null && gridresponse.status != undefined) {
  //       setloader('');
  //       setmaindata(gridresponse.data.result);
  //       //Sorting the table data
  //       const dateArry = gridresponse.data.result.filter((el: any) => {
  //         return (new Date(el.date).getMonth() + '/' + new Date(el.date).getDate() + '/' + new Date(el.date).getFullYear());
  //       });
  //       const sortedArry = dateArry.sort((a: any, b: any) =>
  //         b.date < a.date ? -1 : 1
  //       );
  //       settabledata(sortedArry);
  //     }
  //     else {
  //       setpopupop('GridError');
  //     }
  //     setgridbind(false);
  //   }
  //   gridbind ? genRepLoad() : '';

  // }, [gridbind]);

  useEffect(() => {
    const pagefn = () => {
      const itemsPerPage = 25;
      const endOffset = itemOffset + itemsPerPage;
      if (endOffset > tabledata.length) {
        setoutoffrec(tabledata.length);
      } else {
        const endset = endOffset % tabledata.length;
        endset === 0 ? setoutoffrec(tabledata.length) : setoutoffrec(endset);
      }
      settotrecord(tabledata.length);
      setCurrentItems(tabledata.slice(itemOffset, endOffset));
      setpageCount(Math.ceil(tabledata.length / 25));
    };
    const noRecfn = () => {
      settotrecord(tabledata.length);
      setoutoffrec(tabledata.length);
      setpageCount(0);
    };
    if (tabledata.length != 0) {
      pagefn();
    } else {
      noRecfn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, tabledata]);

  const optionsfill = (zoneDD: any) => {
    const zonvalary: any = zoneDD.map((b: any) => {
      return b.zone;
    });
    const sortedzonenum = zonvalary.sort(function (a: any, b: any) {
      return a - b;
    });
    setzonevalue(sortedzonenum);
  };

  //company Code logic of optionsfill
  // const optionsfill = (companyCode: any, zoneDD: any) => {
  //   console.log('company code', companyCode)
  //   console.log('zoneDD', zoneDD)
  //   if (companyCode === 'Pricing') {
  //     const zonvalary: any = zoneDD.map((b: any) => {
  //       return b.zone;
  //     })
  //     setzonevalue(zonvalary);
  //   }
  //   else {
  //     const zoneArry = zoneDD.filter((f: any) => {
  //       return f.companyCode == companyCode;
  //     });
  //     const zonvalary: any = zoneArry.map((b: any) => {
  //       return b.zone;
  //     })
  //     setzonevalue(zonvalary);
  //   }

  // }

  const buildGridHeader = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {headerdata.map((value: any, index: any) => {
          return value.columnTitle == 'Action' ? (
            <RACTableCell
              key={index}
              className={`${pricing.headingCss} ${classes.textCenter}`}
            >
              Action
            </RACTableCell>
          ) : (
            <RACTableCell key={index} className={`${pricing.headingCss}`}>
              {value.IsSortable ? (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  id={value.columnId}
                  onClick={() =>
                    SortOperation(
                      value.columnId,
                      index,
                      dataColumns,
                      dataValues
                    )
                  }
                >
                  {value.columnTitle + '  '}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortIconUp className={classes.ml2} />
                    ) : (
                      <SortIcon className={classes.ml2} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + '  '}
                </a>
              )}
            </RACTableCell>
          );
        })}
      </>
    );
  };

  const SortOperation = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any
  ) => {
    /* eslint-disable no-debugger */
    // debugger
    try {
      const sortedData =
        datacolumn[index].sortOrder == sortDirection.DESC
          ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
          : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
      const reorderColumns: columns[] = datacolumn;
      reorderColumns[index].sortOrder =
        reorderColumns[index].sortOrder == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : false;
      });

      settabledata([...sortedData]);
      setheaderdata([...reorderColumns]);
    } catch (ex) {
      console.log(ex);
    }
  };
  //new Updated Fixed the comma issue
  const splittingZoneArr = (obj: any) => {
    if (obj.length <= 1) {
      return obj[0];
    } else {
      if (obj.length == 2) {
        return obj[0] + ',' + ' ' + obj[1];
      } else if (obj.length == 3) {
        return obj[0] + ',' + ' ' + obj[1] + ',' + ' ' + obj[2];
      } else if (obj.length > 3) {
        return obj[0] + ',' + ' ' + obj[1] + ',' + ' ' + obj[2] + '...';
      }
    }
  };

  const splitcomma = (obj: any) => {
    const lastzonenum = obj.slice(3, obj.length);
    const lastzonenumsort = lastzonenum.sort(function (a: any, b: any) {
      return a - b;
    });
    return lastzonenumsort.map((el: any, index: any) => {
      if (lastzonenumsort.length == 0 || index == lastzonenumsort.length - 1) {
        return el;
      } else {
        return el + ''; // " return el + ',' + ''; " for Tooltip package
      }
    });
  };

  //Download the Xl Files
  const getReportXlFiles = async (dockey: any) => {
    const key = {
      key: dockey,
      type: 'GET',
    };
    const ReportResposne = await getXlFile(key);
    const link = ReportResposne.data.generatedUrl;
    if (ReportResposne.status == 200 && ReportResposne.status != undefined) {
      const element = document.createElement('a');
      element.setAttribute('href', `${link}`);
      element.setAttribute('style', 'display:none');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (ReportResposne.status == 400) {
      setpopupop('ErrorpopupDoc400');
    } else {
      setpopupop('Errorpopup');
    }
    setDownloadloader('-1');
  };

  const renderTableContent: any = () => {
    return currentItems.map((obj: any, index: any) => {
      return (
        <React.Fragment key={index}>
          <RACTableRow className={classes.bgTransparent}>
            <RACTableCell
              title={obj.zone.length > 3 ? splitcomma(obj.zone) : null}
              className={`${pricing.p36} ${pricing.zonewidth}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textGrey} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                {' '}
                {obj.zone.length > 0 && obj.zone != ''
                  ? splittingZoneArr(
                    obj.zone.sort(function (a: any, b: any) {
                      return a - b;
                    })
                  )
                  : '-'}{' '}
              </Typography>
            </RACTableCell>
            <RACTableCell className={`${pricing.p36} ${pricing.typewidth}`}>
              <Typography
                variant="caption"
                className={`${classes.textGrey} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                {' '}
                {obj.type}{' '}
              </Typography>
            </RACTableCell>
            <RACTableCell className={`${pricing.p36} ${pricing.datewidth}`}>
              <Typography
                variant="caption"
                className={`${classes.textGrey} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                {' '}
                {moment(new Date(obj.date)).format(monthDateFormat) +
                  ' ' +
                  moment(new Date(obj.date)).format('h:mm A')}{' '}
              </Typography>
            </RACTableCell>
            <RACTableCell className={`${pricing.p36} ${pricing.statuswidth}`}>
              <Typography
                variant="caption"
                className={`${classes.textGrey} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                {' '}
                {obj.status}
              </Typography>
            </RACTableCell>
            <RACTableCell
              title={
                Downloadloader != obj.documentKey && Downloadloader != '-1'
                  ? 'Please wait while downloading...'
                  : undefined
              }
              className={`${pricing.actionwidth} ${pricing.textCenter}`}
            >
              {obj.status === 'Pending' || obj.status === 'Failed' ? (
                <RACButton
                  disabled
                  color="primary"
                  className={classes.bgLightBlue}
                >
                  Download
                </RACButton>
              ) : obj.status === 'Completed' ? (
                Downloadloader != obj.documentKey ? (
                  Downloadloader != '-1' ? (
                    <RACButton
                      disabled
                      color="primary"
                      className={classes.bgLightBlue}
                      id={index}
                    >
                      Download
                    </RACButton>
                  ) : (
                    <RACButton
                      color="primary"
                      className={classes.bgLightBlue}
                      id={index}
                      onClick={() => {
                        getReportXlFiles(obj.documentKey);
                        setDownloadloader(obj.documentKey);
                      }}
                    >
                      Download
                    </RACButton>
                  )
                ) : (
                  <CircularProgress
                    style={{ width: '30px', height: '30px' }}
                  ></CircularProgress>
                )
              ) : (
                <RACTableCell
                  className={`${pricing.p36} ${pricing.actionwidth} ${pricing.textCenter}`}
                >
                  {' '}
                  -{' '}
                </RACTableCell>
              )}
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      );
    });
  };

  //old download button logic
  // {
  //   <RACTableCell className={`${pricing.p36} ${pricing.actionwidth} ${pricing.textCenter}`}>
  //             {obj.status === 'Pending' || obj.status === 'Failed' ? (
  //               <RACButton disabled color="primary" className={classes.bgLightBlue} >Download</RACButton>
  //             ) : obj.status === 'Completed' ? (
  //               <RACButton color="primary" className={classes.bgLightBlue}
  //                 onClick={() =>
  //                   getReportXlFiles(obj.documentKey, index)
  //                 }>Download</RACButton>
  //             ) : (
  //               <RACTableCell className={`${pricing.p36} ${pricing.actionwidth} ${pricing.textCenter}`}> - </RACTableCell>
  //             )}
  //           </RACTableCell>
  // }

  // const filterclose = () => {
  //   setpopupop('');
  // }

  const handleOnChangeStatusCheck = (e: any) => {
    //eslint-disable-next-line no-debugger
    //debugger
    if (filterobject.Status !== '0') {
      setfilterobject({ ...filterobject, Status: e.target.value });
    } else {
      setfilterobject({ ...filterobject, Status: '' });
    }
  };

  const dropDwnStatusOptions = () => {
    return [
      { label: 'Select', value: '' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Failed', value: 'Failed' },
      { label: 'Pending', value: 'Pending' },
    ];
  };

  const handleOnChangeFDate = (InputTxt: any) => {
    if (InputTxt == '') {
      setfromdt('');
    }
    const stDatefrm = moment(InputTxt, yearDateFormat);

    const todatefrm = moment(filterobject.To, yearDateFormat);

    const daysRem: any = stDatefrm.diff(todatefrm, 'days');

    const daysRemTo: any = todatefrm.diff(stDatefrm, 'days');

    const dtfrm = new Date();
    const timefrm = dtfrm.toLocaleString('en-US', {
      timeZone: timeZoneChicago,
    });
    const currentfrm = timefrm.split(',')[0];
    const formatedfrm: any = moment(currentfrm).format(yearDateFormat);

    const curdtdiffFrom: any = stDatefrm.diff(formatedfrm, 'days');

    const curdtdiffTO: any = todatefrm.diff(formatedfrm, 'days');

    if (curdtdiffFrom > 0) {
      seterrormsg('futureerror');
    } else {
      seterrormsg('');
    }
    if (curdtdiffTO > 0) {
      seterrormsgTo('futureerror');
    } else {
      seterrormsgTo('');
    }

    if (curdtdiffTO <= 0 && curdtdiffFrom <= 0) {
      if (daysRem > 0) {
        seterrormsg('dateerror');
        seterrormsgTo('');
      } else if (InputTxt == filterobject.To) {
        seterrormsgTo('');
        seterrormsg('');
        // eslint-disable-next-line sonarjs/no-duplicated-branches
      } else {
        seterrormsgTo('');
        seterrormsg('');
      }
    }

    //debugger;
    const TempArr =
      InputTxt !== undefined && InputTxt !== '' && InputTxt != null
        ? InputTxt.split('-')
        : null;
    if (TempArr !== null) {
      const format: any = moment(InputTxt).format(monthDateFormat);
      const temp = InputTxt.replace(format);
      setfromdt(temp);
    }
    if (InputTxt != '' && filterobject.To == '') {
      const dt = new Date();
      const time = dt.toLocaleString('en-US', { timeZone: timeZoneChicago });
      const current = time.split(',')[0];
      const formated: any = moment(current).format(yearDateFormat);
      setfilterobject({ ...filterobject, To: formated });
    }
  };

  const handleOnChangeTDate = (txt: any) => {
    if (txt == '') {
      setfilterobject({ ...filterobject, To: '' });
    }

    const stDate = moment(fromdt, yearDateFormat);
    //const todate = txt;
    const todate = moment(txt, yearDateFormat);
    const daysRem: any = stDate.diff(todate, 'days');
    const dt = new Date();
    const time = dt.toLocaleString('en-US', { timeZone: timeZoneChicago });
    const current = time.split(',')[0];
    const formated: any = moment(current).format(yearDateFormat);

    const curdtdiffTO: any = todate.diff(formated, 'days');

    const curdtdiffFrom: any = stDate.diff(formated, 'days');

    if (curdtdiffFrom > 0) {
      seterrormsg('futureerror');
    } else {
      seterrormsg('');
    }
    if (curdtdiffTO > 0) {
      seterrormsgTo('futureerror');
    } else {
      seterrormsgTo('');
    }
    if (curdtdiffFrom <= 0 && curdtdiffTO <= 0) {
      if (daysRem > 0) {
        seterrormsgTo('dateerror');
        seterrormsg('');
      } else if (fromdt == txt) {
        seterrormsgTo('');
        seterrormsg('');
      } else {
        seterrormsg('');
        seterrormsgTo('');
      }
    }
    const TempArr =
      txt !== undefined && txt !== '' && txt != null ? txt.split('-') : null;
    if (TempArr !== null) {
      const formin: any = moment(txt).format(monthDateFormat);
      const temp = txt.replace(formin);
      setfilterobject({ ...filterobject, To: temp });
    }
  };

  const FilterOperation = () => {
    if (
      filterobject.Status !== null &&
      filterobject.Status !== '' &&
      fromdt == '' &&
      filterobject.To == ''
    ) {
      const arry = maindata.filter((el: any) => {
        return el.status == filterobject.Status;
      });
      const sortarrays = arry.sort((a: any, b: any) =>
        a.date < b.date ? -1 : 1
      );
      settabledata(sortarrays);
      if (
        headerdata.map((value: any) => {
          value.columnId == 'date';
          value.sortOrder = sortDirection.DESC;
        })
      )
        setpopupop('');
    } else if (
      fromdt !== '' &&
      filterobject.To !== '' &&
      filterobject.Status == ''
    ) {
      const arry = maindata.filter((el: any) => {
        return (
          moment(el.date).format(yearDateFormat) >=
          moment(fromdt).format(yearDateFormat) &&
          moment(el.date).format(yearDateFormat) <=
          moment(filterobject.To).format(yearDateFormat)
        );
      });
      const sortarrays = arry.sort((a: any, b: any) =>
        a.date < b.date ? -1 : 1
      );
      settabledata(sortarrays);
      if (
        headerdata.map((value: any) => {
          value.columnId == 'date';
          value.sortOrder = sortDirection.DESC;
        })
      )
        setpopupop('');
    } else if (
      fromdt !== '' &&
      filterobject.To !== '' &&
      filterobject.Status !== ''
    ) {
      const arry = maindata.filter((el: any) => {
        return (
          moment(el.date).format(yearDateFormat) >=
          moment(fromdt).format(yearDateFormat) &&
          moment(el.date).format(yearDateFormat) <=
          moment(filterobject.To).format(yearDateFormat) &&
          el.status == filterobject.Status
        );
      });
      const sortarrays = arry.sort((a: any, b: any) =>
        a.date < b.date ? -1 : 1
      );
      settabledata(sortarrays);
      if (
        headerdata.map((value: any) => {
          value.columnId == 'date';
          value.sortOrder = sortDirection.DESC;
        })
      )
        setpopupop('');
    }
  };

  const ApplyBtnDisable = () => {
    // debugger
    if (
      filterobject.Status !== '' &&
      fromdt !== '' &&
      filterobject.To !== '' &&
      errormsg === '' &&
      errormsgTo === ''
    ) {
      return false;
    } else if (
      filterobject.Status == '' &&
      fromdt !== '' &&
      filterobject.To !== '' &&
      errormsg === '' &&
      errormsgTo === ''
    ) {
      return false;
    } else if (
      filterobject.Status !== '' &&
      fromdt == '' &&
      filterobject.To == ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  const clearButton = () => {
    if (
      headerdata.map((value: any) => {
        value.columnId == 'date';
        value.sortOrder = sortDirection.ASC;
      })
    )
      setfilterobject({ ...filterobject, Status: '', To: '' });
    setfromdt('');
    seterrormsg('');
    seterrormsgTo('');
    settabledata(maindata.sort((a: any, b: any) => (b.date < a.date ? -1 : 1)));
    setItemOffset(0);
    {
      pginitial != 0 ? setpginitial(0) : undefined;
    } //new logic for pagination
  };

  const Clearbtndisable = () => {
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (
      filterobject.Status == '' &&
      filterobject.From == '' &&
      filterobject.To == ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const zonedata = () => {
    return zonevalue.map((elem: any) => {
      return {
        label: elem,
        value: elem,
      };
    });
  };

  const genReportbtnStatus = () => {
    if (genType == '' || genType === '0') {
      return true;
    }
    // eslint-disable-next-line sonarjs/no-identical-expressions
    else if (
      (genType === 'Pricing' && genZone.length === 0) ||
      (genType === 'StoreZone' && genZone.length === 0) ||
      (genType === 'Autoterm' && genZone.length === 0) ||
      (genType === 'Package' && genZone.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  // eslint-disable-next-line sonarjs/no-identical-functions
  const zoneDDstatus = () => {
    if (
      genType == '' ||
      genType === 'Unassigned' ||
      genType === 'Zone' ||
      genType === '0' ||
      genType === 'OverallMissingPrice'
    ) {
      return true;
    }
  };

  const genReportBtnClick = async () => {
    setgenZone([]);
    setgenType('0');
    setpopupop('');
    const zoneReqAry: any = [];
    let req: any;

    if (genZone.length != 0 || genType != '') {
      genZone.map((obj: any) => {
        zoneReqAry.push(obj.value);
      });
      if (genType == 'Zone' || genType == 'Unassigned') {
        req = {
          type: genType,
          //companyCode: companyCode
        };
      } else {
        const zonelength = zonevalue.length;
        if (zonelength == 1 && zoneReqAry.length == 1) {
          req = {
            zone: zoneReqAry,
            type: genType,
            //companyCode: companyCode
          };
        } else {
          req = {
            zone: zoneReqAry.length === zonelength ? ['ALL'] : zoneReqAry,
            type: genType,
            //companyCode: companyCode
          };
        }
      }
      setloader('masterLoader');
      //setpopupop('gridloader');
      const genRepRes = await generateReport(req);
      if (
        genRepRes.status == 200 &&
        genRepRes.status != undefined &&
        genRepRes.status != '' &&
        genRepRes.status != null
      ) {
        setpopupop('genreport');
      } else if (genType == 'OverallMissingPrice' && genRepRes.status == 400) {
        setpopupop('Generate400')
      } else {
        setpopupop('Errorpopup');
      }
      setloader('');
    }
  };

  const typeSelectDD = () => {
    return [
      { label: 'Select', value: '0' },
      { label: 'Autoterm', value: 'Autoterm' },
      { label: 'Package', value: 'Package' },
      { label: 'Pricing', value: 'Pricing' },
      { label: 'Overall Missing Price', value: 'OverallMissingPrice' },
      { label: 'Store-Zone', value: 'StoreZone' },
      { label: 'Un-Assigned Store', value: 'Unassigned' },
      { label: 'Zone', value: 'Zone' },
    ];

    // if (companyCode === 'Pricing') {
    //   return [
    //     { label: "Select", value: "0" },
    //     { label: "Autoterm", value: "Autoterm" },
    //     { label: "Package", value: "Package" },
    //     { label: "Pricing", value: "Pricing" },
    //     { label: "Store-Zone", value: "StoreZone" },
    //     { label: "Un-AssignedStore", value: "Unassigned" },
    //     { label: "Zone", value: "Zone" },
    //   ]
    // }
    // else {
    //   return [
    //     { label: "Select", value: "0" },
    //     { label: "Autoterm", value: "Autoterm" },
    //     { label: "Package", value: "Package" },
    //     { label: "Pricing", value: "Pricing" },
    //     { label: "Store-Zone", value: "StoreZone" },
    //     { label: "Zone", value: "Zone" },
    //   ]
    // }
  };

  const getAllOptions: any = () => zonedata().map((option: any) => option.value);

  const generateReportPopup = () => {
    return (
      <>
        <Grid className={`${classes.mx2} ${pricing.multioverflow}`}>
          <Grid container spacing={2} className={pricing.multioverflow}>
            <Grid
              item
              xs={12}
              sm={6}
              md={12}
              lg={12}
              className={pricing.pbcustom}
            >
              <RACSelect
                inputLabel="Type"
                defaultValue={genType !== '' ? genType : '0'}
                onChange={(e: any) => {
                  setgenZone([]);
                  setgenType(e.target.value);
                  if (e.target.value == 'OverallMissingPrice') {
                    setgenZone(getAllOptions());
                  }
                  if (e.target.value == 'Pricing') {
                    setSelectAll(false)
                  } else {
                    setSelectAll(true)
                  }
                }}
                options={typeSelectDD()}
                inputLabelClassName={`${pricing.Bottom}`}
              ></RACSelect>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={12}
              lg={12}
              className={pricing.pbcustom1}
            >
              <Typography className="racstrap-tablecell-BgColor">
                Zone
              </Typography>
              <MultiSelect
                options={zonedata()}
                value={genZone}
                className={`${pricing.demo} ${pricing.customMultiselect} ${pricing.Top}`}
                onChange={(e: any) => {
                  setgenZone(e);
                }}
                labelledBy={'Select'}
                hasSelectAll={selectAll}
                disabled={zoneDDstatus()}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={` ${classes.m2} ${classes.p2} ${classes.componentRight} ${pricing.genbtn}`}
            >
              <RACButton
                variant="outlined"
                color="default"
                className={classes.mr2}
                onClick={() => {
                  setgenZone([]);
                  setgenType('0');
                  setpopupop('');
                }}
              >
                Cancel
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                disabled={genReportbtnStatus()}
                onClick={() => genReportBtnClick()}
              >
                Generate{' '}
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const reportCreatedPopup = () => {
    return (
      <Grid>
        <Grid>
          <SuccesIcon className={`${pricing.successicon}`}></SuccesIcon>
        </Grid>
        <Grid>
          <Typography className={`${pricing.genpop}`}>
            Your report is being generated.
          </Typography>
          <RACButton
            className={`${pricing.genpopupbtn}`}
            variant="contained"
            color="primary"
            onClick={() => {
              setgridbind(true);
              setItemOffset(0);
              setpopupop('');
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const ErrorPopup = () => {
    return (
      <Grid className={pricing.textSpaceNorecords}>
        <Grid className={pricing.gridtextcenterNorecords}>
          <SomethingWrong className={pricing.racErrorIcon} />
        </Grid>
        {popupop == 'ErrorpopupDoc400' ? (
          <Grid className={`${pricing.textcenterImage} ${classes.fontBold}`}>
            File does not exist !
          </Grid>
        ) : popupop == 'Errorpopup' ? (
          <Grid className={`${pricing.textcenterImage} ${classes.fontBold}`}>
            Something Went Wrong !
          </Grid>
        ) : popupop == 'Generate400' ? (
          <Grid className={`${pricing.textcenterImage} ${classes.fontBold}`}>
            Please wait while the previous missing report is being generated!
          </Grid>
        ) : null}
        <Grid>
          <RACButton
            className={`${pricing.gridgenpopupbtn}`}
            variant="contained"
            color="primary"
            onClick={() => {
              setpopupop('');
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const GridErrorPopup = () => {
    return (
      <Grid className={pricing.textSpaceNorecords}>
        <Grid className={pricing.gridtextcenterNorecords}>
          <SomethingWrong className={pricing.racErrorIcon} />
        </Grid>
        <Grid className={`${pricing.textcenterImage} ${classes.fontBold}`}>
          Something Went Wrong !
        </Grid>
        <Grid>
          <RACButton
            className={`${pricing.gridgenpopupbtn}`}
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
              setpopupop('');
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const rendernorecordsheader = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${pricing.p36}${pricing.zonewidth}`}>
          Zone
        </RACTableCell>
        <RACTableCell className={`${pricing.p36}${pricing.typewidth}`}>
          Type
        </RACTableCell>
        <RACTableCell className={`${pricing.p36}${pricing.datewidth}`}>
          Date & Time
        </RACTableCell>
        <RACTableCell className={`${pricing.p36}${pricing.statuswidth}`}>
          Status
        </RACTableCell>
        <RACTableCell
          className={`${pricing.p36} ${classes.textCenter} ${pricing.actionwidth}`}
        >
          {' '}
          Action
        </RACTableCell>
      </React.Fragment>
    );
  };

  const handlePageClick = (event: any) => {
    scrollTop;
    {
      event.selected === 0 ? setpginitial(0) : setpginitial(event.selected);
    }
    const newOffset = (event.selected * 25) % maindata.length;
    setItemOffset(newOffset);
  };

  const scrollTop = (ref: any) => {
    if (ref) {
      const element = ref;
      const clientWidth = element.getBoundingClientRect().height;
      const negCal = clientWidth * 2;
      const negVal = clientWidth - negCal;
      window.scrollBy(0, negVal);
    }
  };

  const openFilterpop = () => {
    // To disable the future dates in  RACDate Pickers
    const dt = new Date();
    const time = dt.toLocaleString('en-US', { timeZone: timeZoneChicago });
    const current = time.split(',')[0];
    const mindt = moment().subtract(110, 'years').format(yearDateFormat);
    const maxdt = moment(current).format(yearDateFormat);
    return (
      <Card className={`${classes.positionAbsolute} ${classes.filterDropDown}`}>
        <CardContent>
          <Grid className={`${classes.componentSpaceBetween} ${classes.mb2}`}>
            <Typography variant="h5">Advanced Filter</Typography>
            <FilterClose
              onClick={() => {
                setpopupop('');
              }}
            ></FilterClose>
          </Grid>
          <Grid container spacing={4} className={classes.mb2}>
            <Grid item xs={12} sm={4} md={4} lg={6}>
              <RACSelect
                inputLabel="Status"
                onChange={(e) => handleOnChangeStatusCheck(e)}
                defaultValue={filterobject.Status}
                options={dropDwnStatusOptions()}
              ></RACSelect>
            </Grid>
          </Grid>
          <Grid className={`${classes.mb2}`}>
            <Typography variant="h5">Date Range</Typography>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <RACDatePicker
                label="From"
                onChange={(e: any) => handleOnChangeFDate(e)}
                inputProps={{
                  min: mindt,
                  max: maxdt,
                }}
                value={fromdt}
              ></RACDatePicker>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <RACDatePicker
                label="To"
                inputProps={{
                  min: mindt,
                  max: maxdt,
                }}
                value={filterobject.To}
                onChange={(e: any) => handleOnChangeTDate(e)}
              ></RACDatePicker>
            </Grid>
          </Grid>
          <Grid className={pricing.validerroralign}>
            {errormsg == 'dateerror' ? (
              <span style={{ fontFamily: fontOpens, color: 'red' }}>
                From date should be Lesser than To date
              </span>
            ) : errormsg == 'futureerror' ? (
              <span style={{ fontFamily: fontOpens, color: 'red' }}>
                Invalid From Date
              </span>
            ) : (
              ''
            )}
          </Grid>
          <Grid className={pricing.validerroralign}>
            {errormsgTo == 'dateerror' ? (
              <span style={{ fontFamily: fontOpens, color: 'red' }}>
                To date should be greater than from date
              </span>
            ) : errormsgTo == 'futureerror' ? (
              <span style={{ fontFamily: fontOpens, color: 'red' }}>
                Invalid To Date
              </span>
            ) : (
              ''
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={` ${classes.mt4}  ${classes.componentRight}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mr2}
              disabled={Clearbtndisable()}
              onClick={() => clearButton()}
            >
              Clear
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mr2}
              disabled={ApplyBtnDisable()}
              onClick={() => {
                setItemOffset(0);
                {
                  pginitial != 0 ? setpginitial(0) : undefined;
                }
                FilterOperation();
              }}
            >
              Apply
            </RACButton>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Grid container ref={scrollTop}>
        <Grid item md={12}>
          {loader == 'masterLoader' ? (
            <Grid className={pricing.masterLoader}>
              <Grid className={pricing.Loader}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : null}

          <Grid container className={classes.gridLeft} style={{ padding: '10px 10px 5px 10px' }}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={`${classes.textLeft} ${classes.ml5px}`} variant="h5">Reports</Typography>
              <RACInfoIcon
                infoTitle="This page enables the Pricing Department to generate a report 
                and download the generated report to view in excel format."
                placement="right"
                classes={{
                  tooltip: pricing.tooltip,
                  arrow: pricing.Tooltiparrow,
                }}
                iconClassname={pricing.infocss}
              ></RACInfoIcon>
            </Grid>
            <Grid className={classes.floatRight}><MissingPrice /></Grid>
          </Grid>

          <Card
            className={`${classes.m2} ${classes.racCard} ${classes.overFlowVisible} ${classes.mbFooterSpacing}`}
          >
            <CardContent>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={12}
                className={`${classes.componentRight} ${classes.m3}`}
              >
                <Grid className={`${classes.positionRelative}`}>
                  <RACButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    id="filterIcon"
                    className={`${classes.mr2} ${classes.filterBtn}`}
                    onClick={() => {
                      // setpopupop('filterpopupon');
                      popupop == ''
                        ? setpopupop('filterpopupon')
                        : setpopupop('');
                    }}
                  >
                    <RacFilter></RacFilter>
                  </RACButton>
                  {popupop == 'filterpopupon' ? openFilterpop() : null}
                </Grid>
              </Grid>
              {loader == 'gridloader' ? (
                <Grid className={pricing.textSpaceNorecords}>
                  <CircularProgress />
                </Grid>
              ) : tabledata.length > 0 ? (
                <RACTable
                  renderTableHead={() => buildGridHeader(headerdata, tabledata)}
                  renderTableContent={renderTableContent}
                />
              ) : (loader != 'masterLoader' && popupop != 'GridError') ||
                nocontent == true ? (
                <Grid>
                  <RACTable renderTableHead={rendernorecordsheader} />
                  <Grid className={pricing.textSpaceNorecords}>
                    <Grid className={pricing.textcenterNorecords}>
                      <NorecordsIcon className={pricing.racErrorIcon} />
                    </Grid>
                    <Grid className={pricing.textcenterImage}>
                      No Records Found !
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {loader != 'masterLoader' &&
                popupop != 'GridError' &&
                tabledata.length > 0 ? (
                <Grid className={`${classes.mb1} ${classes.textRight}`}>
                  <Grid className={`${pricing.totrec}`}>
                    <Typography>
                      # of Records:{' '}
                      {pginitial != 0
                        ? outoffrec
                        : tabledata.slice(0, 25).length}
                      /{totrecord}
                    </Typography>
                  </Grid>
                  <ReactPaginate
                    nextLabel=">"
                    onPageChange={(num) => handlePageClick(num)}
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={0}
                    previousLabel="<"
                    breakLabel=""
                    pageClassName={pricing.pagination}
                    pageLinkClassName={pricing.pagelink}
                    previousLinkClassName={
                      pginitial === 0
                        ? pricing.paginationprevnextdisable
                        : pricing.paginationprevnext
                    }
                    nextLinkClassName={
                      pginitial === pageCount - 1
                        ? pricing.paginationprevnextdisable
                        : pricing.paginationprevnext
                    }
                    breakClassName={pricing.pagination}
                    breakLinkClassName={pricing.pagination}
                    containerClassName={pricing.pagination}
                    activeClassName={pricing.pageactive}
                    forcePage={pginitial === 0 ? 0 : undefined} //new logic
                  />
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.fixedFooter}>
        <Grid>
          <RACButton
            variant="outlined"
            onClick={() => history.push('/dashboard')}
            color="primary"
            className={`${classes.borderNone} ${classes.textBlack}`}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setpopupop('generatepopup')}
          >
            Generate New Report
          </RACButton>
        </Grid>
      </Grid>

      <RACModalCard
        isOpen={popupop == 'generatepopup' ? true : false}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setpopupop('')}
        // eslint-disable-next-line react/no-children-prop
        children={generateReportPopup()}
        title="Generate New Report"
        className={pricing.demopopup}
      />
      <RACModalCard
        isOpen={popupop === 'genreport' ? true : false}
        closeIcon={true}
        maxWidth="xs"
        onClose={() => {
          setpopupop('');
          setgridbind(true);
          setItemOffset(0);
        }}
        // eslint-disable-next-line react/no-children-prop
        children={reportCreatedPopup()}
        borderRadius={borRadius}
      />
      <RACModalCard
        isOpen={
          popupop === 'Errorpopup' || popupop == 'ErrorpopupDoc400' || popupop == 'Generate400'
            ? true
            : false
        }
        closeIcon={true}
        maxWidth="xs"
        onClose={() => setpopupop('')}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorPopup()}
        borderRadius={borRadius}
      />
      <RACModalCard
        isOpen={popupop === 'GridError' ? true : false}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={GridErrorPopup()}
        title="Pricing Report"
        borderRadius={borRadius}
      />
    </>
  );
}
